$headerHeight: 50px;
$headerHeightSM: 60px;
#header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: $zindex-sticky;
  transition: $transition-base;
  width: 100%;
  height: $headerHeight;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  @include media-breakpoint-up(lg) {
    height: $headerHeightSM;
    padding: 0 30px;
  }
  &.scrolled {
    background-color: $body-bg;
  }
  .logo {
    display: inline-block;

    margin: 0;
    a {
      text-decoration: none;
      font-size: 25px;
      font-weight: bold;
      color: $body-color;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 32px;
      }
    }
  }
}
