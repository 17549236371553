#footer {
  position: relative;
  padding: 50px 0;
  .footer-square {
    position: absolute;
    padding-right: 6vw;
    top: 70%;
    right: 0;
    text-align: end;
    @include media-breakpoint-up(sm) {
      padding-right: 10vw;
      top: 40px;
    }
    img {
      width: 90%;
      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
  }
  .logo {
    text-decoration: none;
    p {
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 50px;
      color: $body-color;
      @include media-breakpoint-up(sm) {
        font-size: 32px;
      }
    }
  }
  .footerGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 50px;
    margin-bottom: 50px;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
    .right {
      .cont {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 6px;
        p {
          margin: 0;
        }
      }
    }
    .left {
      .sns {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          a {
            text-decoration: none;
            color: $body-color;
            font-weight: bold;
            font-size: 13px;
            transition: $transition-base;
            img {
              padding-right: 5px;
            }
            &:hover {
              color: $primary01;
            }
          }
        }
      }
    }
  }
  .copyright {
    margin: 0;
    @include media-breakpoint-up(sm) {
      text-align: end;
    }
  }
}
