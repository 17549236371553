#featureArea {
  position: relative;
  .featureArea-square01 {
    position: absolute;
    padding-left: 6vw;
    top: 20px;
    left: 0;
    @include media-breakpoint-up(sm) {
      padding-left: 10vw;
      top: 40px;
    }
    img {
      width: 70%;
      @include media-breakpoint-up(sm) {
        width: 100%;
      }
    }
  }
  .featureArea-square02 {
    position: absolute;
    padding-right: 6vw;
    bottom: -40px;
    right: 0;
    text-align: end;
    @include media-breakpoint-up(sm) {
      padding-right: 10vw;
      bottom: -100px;
    }
    img {
      width: 70%;
      @include media-breakpoint-up(sm) {
        width: 100%;
      }
    }
  }
  .brandContainer {
    .midashi1 {
    }
    .featureAreaGroup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 15vw;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        align-items: stretch;
        gap: 3vw;
      }
      .featureAreaSingle {
        flex: 1;
        .header {
          text-align: center;
          .point {
            color: $primary02;
            font-family: "Hiragino Kaku Gothic ProN";
            font-size: 10px;
            font-weight: bold;
            margin: 0;
            line-height: 1em;
          }
          .number {
            color: #1e1e1e;
            font-family: "Impact";
            font-size: 40px;
            font-weight: bold;
            margin: 0;
            line-height: 1em;
          }
        }
        .body {
          margin-top: -20px;
          padding: 50px 25px 20px;
          background-color: $white;
          height: 100%;
          @include media-breakpoint-up(sm) {
            padding-bottom: 0;
          }
          .img {
            margin-bottom: 20px;
            img {
              width: 100%;
            }
          }
          .pointText {
            text-align: center;
            font-weight: bold;
            margin: 0;
            @include media-breakpoint-up(sm) {
              text-align: left;
            }
            @include media-breakpoint-up(lg) {
              text-align: center;
            }
            br {
              @include media-breakpoint-up(sm) {
                display: none;
              }
              @include media-breakpoint-up(lg) {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
