.formTable {
  width: 100%;
  margin-bottom: 50px;
  @include media-breakpoint-up(sm) {
    display: table;
    margin-bottom: 100px;
  }
  dl {
    display: block;
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(sm) {
      display: table-row;
    }
    dt {
      display: block;
      font-weight: bold;
      text-align: left;
      padding: 15px 0;
      margin: 0;
      @include media-breakpoint-up(sm) {
        display: table-cell;
        padding: 38px 0;
        width: 1%;
        white-space: nowrap;
        vertical-align: top;
      }
      
      .title p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin: 0;
        @include media-breakpoint-up(sm) {
          justify-content: space-between;
          gap: 25px;
        }
        span {
          color: $white;
          font-weight: 300;
          font-size: 13px;
          padding: 1px 8px;
        }
      }
      .badge {
        padding: 10px 15px;
      }
    }
    dd {
      display: block;
      text-align: left;
      padding: 0 0 15px;
      margin: 0;
      @include media-breakpoint-up(sm) {
        display: table-cell;
        padding: 20px 0 20px 60px;
      }
      p {
        margin: 0;
      }
    }
    &:last-child {
      dt {
        @include media-breakpoint-up(sm) {
          padding: 40px 0;
          vertical-align: top;
        }
      }
      dd {
        padding-bottom: 0;
      }
    }
  }
}
input,textarea {
  width: 100%;
  border: none;
  border-radius: 0;
  padding: 0 0 15px;
  margin: 0;
  padding: 13px 10px;
  @include media-breakpoint-up(sm) {
    padding: 18px 15px;
  }
}
input::placeholder {
  color: #808080;
}
textarea::placeholder {
  color: #808080;
}