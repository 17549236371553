#aboutArea {
  position: relative;
  .aboutArea-square01 {
    position: absolute;
    padding-left: 6vw;
    top: 20px;
    left: 0;
    @include media-breakpoint-up(sm) {
      padding-left: 10vw;
      top: 40px;
    }
    img {
      width: 70%;
      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
  }
  .aboutArea-square02 {
    position: absolute;
    padding-right: 6vw;
    bottom: 20px;
    right: 0;
    text-align: end;
    @include media-breakpoint-up(lg) {
      padding-right: 10vw;
      bottom: 40px;
    }
    img {
      width: 70%;
      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
  }
  .brandContainer {
    @include media-breakpoint-up(sm) {
      margin: 0 10vw;
    }
    .midashi1 {
    }
    .aboutAreaText01 {
      text-align: left;
      font-weight: bold;
      margin-bottom: 50px;
      @include media-breakpoint-up(sm) {
        text-align: center;
        margin-bottom: 80px;
      }
    }
    .img {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      padding: 0 10vw;
      @include media-breakpoint-up(sm) {
        padding: 0;
      }
      img {
      }
    }
    .aboutAreaText02 {
      text-align: left;
      font-weight: bold;
      margin: 0;
      @include media-breakpoint-up(sm) {
        text-align: center;
      }
    }
  }
}
