#answerArea {
  background-color: $primary01;
  position: relative;
  .triangle {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    img {
    }
  }
  .brandContainer {
    .midashi1 {
      text-align: center;
      .ja {
        span {
          line-height: 1em;
          background: linear-gradient(
            transparent 70%,
            rgba($color: $white, $alpha: 0.8) 70%
          );
        }
      }
    }
    .answerAreaText {
      font-weight: bold;
      margin: 0;
      @include media-breakpoint-up(sm) {
        text-align: center;
      }
    }
  }
}
