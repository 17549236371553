.toggleMenu {
  position: fixed;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: $zindex-fixed;
  min-width: $headerHeight;
  min-height: $headerHeight;
  @include media-breakpoint-up(sm) {
    min-width: $headerHeightSM;
    min-height: $headerHeightSM;
  }
  .toggleMenuBtn {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    width: $headerHeight;
    height: $headerHeight;
    display: flex;
    gap: 8%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-up(sm) {
      width: $headerHeightSM;
      height: $headerHeightSM;
    }
    .menuGroup {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2px;
      @include media-breakpoint-up(sm) {
        gap: 5px;
      }
      i {
        color: $body-color;
        transition: $transition-base;
      }
      p {
        font-weight: bold;
        font-size: 10px;
        margin: 0;
        letter-spacing: 0;
        line-height: 1em;
        transition: $transition-base;
      }
    }
    cursor: pointer;
    .bar,
    &::before,
    &::after {
      display: none;
      width: 36%;
      height: 1px;
      background-color: transparent;
      content: "";
      transition: $transition-base;
    }
    &::after {
      transform: scaleX(60%);
      transform-origin: left;
    }
    &:hover {
      &::after {
        transform: scaleX(100%);
      }
      i {
        color: $white;
      }
      p {
        color: $white;
      }
    }
    &.active {
      .menuGroup {
        display: none;
      }
      .bar {
        opacity: 0;
        display: none;
        position: absolute;
      }
      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 32%;
        transform: rotate(45deg);
        transform-origin: center;
        background-color: $white;
      }
      &::after {
        display: block;
        position: absolute;
        top: 50%;
        right: 32%;
        transform: rotate(-45deg);
        transform-origin: center;
        background-color: $white;
      }
    }
  }
  .cont {
    padding: 30vh 6vw 0;
    background-color: $body-color;
    color: $white;
    position: relative;
    z-index: 1;
    height: 100vh;
    overflow-y: auto;
    @include media-breakpoint-up(sm) {
      padding: 40vh 10vw 0;
      width: 100vw;
    }
    .sns {
      list-style: none;
      padding: 0;
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 15px;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 50px;
      }
      li {
        a {
          text-decoration: none;
          color: $white;
          font-weight: bold;
          font-size: 13px;
          transition: $transition-base;
          img {
            padding-right: 10px;
            font-size: 15px;
          }
          &:hover {
            color: $primary01;
          }
        }
      }
    }
    .togglebtn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 350px;
      background-color: $white;
      padding: 20px 0;
      border: solid 1px $body-color;
      border-radius: 50px;
      text-decoration: none;
      position: relative;
      margin: 0 auto;
      transition: $transition-base;
      @include media-breakpoint-up(sm) {
        width: 300px;
        padding: 20px 0;
      }
      p {
        color: $body-color;
        margin: 0;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
          font-size: 20px;
        }
      }
      i {
        color: $body-color;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        @include media-breakpoint-up(sm) {
          right: 30px;
        }
      }
      &:hover {
        background-color: $body-color;
        border: solid 1px $white;
        p {
          color: $white;
        }
        i {
          color: $white;
        }
      }
    }
  }
}
