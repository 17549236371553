#contactArea {
  position: relative;
  margin-bottom: 20px;
  @include media-breakpoint-up(sm) {
    margin: 0;
  }
  .contactArea-square01 {
    position: absolute;
    padding-right: 6vw;
    top: -50px;
    right: 0;
    text-align: end;
    @include media-breakpoint-up(lg) {
      padding-right: 10vw;
      top: 0;
    }
    img {
      width: 60%;
      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
  }
  .contactArea-square02 {
    position: absolute;
    padding-left: 6vw;
    bottom: 0;
    left: 0;
    @include media-breakpoint-up(sm) {
      padding-left: 10vw;
      bottom: 10vw;
    }
    img {
      width: 50%;
      @include media-breakpoint-up(sm) {
        width: auto;
      }
    }
  }
  .brandContainer {
    @include media-breakpoint-up(lg) {
      margin: 0 10vw;
    }
    .midashi1 {
    }
    .body {
      .formTable {
      }

      .contactBtn {
        position: relative;
        text-align: center;
        margin: 0 auto;
        background: $primary02;
        text-decoration: none;
        width: 280px;
        height: 60px;
        transition: $transition-base;
        // color: $white;
        p {
          margin: 0;
          height: 100%;
          line-height: 1em;
          .wpcf7-form-control.has-spinner.wpcf7-submit {
            background-color: transparent;
            padding: 0;
            color: $white;
            line-height: 15px;
            height: 100%;
          }
        }
        i {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          color: $white;
        }
        // p {
        //   position: relative;
        //   color: $white;
        //   font-size: 15px;
        //   margin: 0;
        //   white-space: nowrap;
        // }
        &:hover {
          background: $body-color;
        }
      }
    }
  }
}
