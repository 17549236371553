#flowArea {
  position: relative;
  .flowArea-square {
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 6vw;
    text-align: end;
    @include media-breakpoint-up(lg) {
      left: 20vw;
      right: auto;
      top: 20%;
      padding-right: 0;
    }
    img {
      width: 70%;
      @include media-breakpoint-up(sm) {
        width: 100%;
      }
    }
  }
  .brandContainer {
    padding-right: 0;
    .flowGroup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        gap: 100px;
      }
      .midashi1 {
      }
      .bg-primary01 {
        width: 100%;
        padding: 60px 6vw 60px 30px;
        position: relative;
        z-index: -1;
        @include media-breakpoint-up(sm) {
          flex: 1;
          padding: 100px 10vw 100px 100px;
        }
        &::after {
          content: "";
          display: block;
          width: 2px;
          height: 100%;
          box-sizing: border-box;
          background-color: $white;
          position: absolute;
          top: 0;
          left: calc(30px + 30px);
          z-index: -1;
          @include media-breakpoint-up(sm) {
            left: calc(40px + 100px);
          }
        }
        .stepGroup {
          list-style: none;
          font-weight: bold;
          padding: 0;
          margin: 0;

          .stepSingle {
            margin-bottom: 60px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: calc(30px + 15px);
            @include media-breakpoint-up(sm) {
              margin-bottom: 55px;
              gap: calc(30px + 30px);
            }
            &:last-child {
              margin: 0;
            }
            .circle {
              border-radius: 50%;
              max-width: 100%;
              max-height: 100%;
              width: 60px;
              height: 60px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              @include media-breakpoint-up(sm) {
                width: 80px;
                height: 80px;
              }

              .step {
                margin: 0;
                font-size: 10px;
                line-height: 1em;
                letter-spacing: -0.05em;
              }
              .number {
                margin: 0;
                font-size: 32px;
                line-height: 1em;
                font-family: "Impact";
                @include media-breakpoint-up(sm) {
                  font-size: 40px;
                }
              }
            }
            .cont {
              position: relative;
              display: inline-block;
              padding: 5px 10px;
              min-width: 200px;
              max-width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              gap: 5px;
              @include media-breakpoint-up(sm) {
                min-width: 300px;
                padding: 5px 20px;
              }
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: -40px;
                margin-top: -10px;
                border: 10px solid transparent;
                border-right: 30px solid $white;
              }
              img {
                width: 40px;
                @include media-breakpoint-up(sm) {
                  width: auto;
                }
              }
              p {
                margin: 0;
                @include media-breakpoint-up(sm) {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}
