#problemArea {
  background-color: $body-color;
  .brandContainer{
    .midashi1{}
    .row{
      .col{
        .img{
          text-align: center;
          margin-bottom: 20px;
          img{}
        }
        .text{
          text-align: center;
          color: $white;
          margin: 0;
        }
      }
    }
  }
}
