#faqArea {
  @include media-breakpoint-up(lg) {
    position: relative;
  }
  .faqArea-square {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      padding-left: 10vw;
    }
    img {
      width: 70%;
      @include media-breakpoint-up(sm) {
        width: 100%;
      }
    }
  }
  .brandContainer {
    .faqGroup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        gap: 50px;
      }
      .midashi1 {
      }
      .faq {
        width: 100%;
        @include media-breakpoint-up(sm) {
          flex: 1;
        }
        .faqSingle {
          border-bottom: solid 1px #dedede;
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $body-color;
            text-decoration: none;
            width: 100%;
            padding: 20px 0;
            gap: 15px;
            @include media-breakpoint-up(sm) {
              gap: 24px;
            }
            .icon {
              font-size: 20px;
              font-weight: bold;
              font-family: $font-family-en;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: $primary01;
              color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .title {
              flex: 1;
              font-size: 16px;
              font-weight: bold;
              margin: 0;
            }
            &.collapsed {
              &::after {
                transform: rotate(0);
                font-size: 18px;
                align-self: center;
                margin-left: 15px;
                margin-right: 15px;
                font-family: "Font Awesome 5 Pro";
                content: "\f078";
                transition: $transition-base;
                @include media-breakpoint-up(sm) {
                  margin-left: 33px;
                  margin-right: 33px;
                }
              }
            }
            &::after {
              transform: rotate(180deg);
              font-size: 16px;
              align-self: center;
              margin-left: 15px;
              margin-right: 15px;
              font-family: "Font Awesome 5 Pro";
              content: "\f078";
              transition: $transition-base;
              @include media-breakpoint-up(sm) {
                margin-left: 33px;
                margin-right: 33px;
              }
            }
          }
          .body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $body-color;
            text-decoration: none;
            width: 100%;
            padding: 0 0 20px;
            gap: 15px;
            @include media-breakpoint-up(sm) {
              padding: 0 0 20px;
              gap: 24px;
            }
            .icon {
              font-size: 20px;
              font-weight: bold;
              font-family: $font-family-en;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: #3d3d3d;
              color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .cont {
              flex: 1;
              p {
                margin: 0;
              }
            }
          }
          &:last-child{
            border: none;
          }
        }
      }
    }
  }
}
