#heroArea {
  position: relative;
  .img {
    height: 100vh;
    @include media-breakpoint-up(sm) {
      height: 50vh;
    }
    @include media-breakpoint-up(lg) {
      height: 100vh;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .heroAreaText {
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 15px;
    padding: 0 6vw;
    font-weight: bold;
    @include media-breakpoint-up(sm) {
      margin-bottom: 40px;
      padding: 0;
      left: 50px;
    }
    @include media-breakpoint-up(lg) {
      left: 100px;
    }
    .title {
      font-size: 35px;
      margin-bottom: 10px;
      line-height: 1.5em;
      @include media-breakpoint-up(sm) {
        font-size: 40px;
        margin-bottom: 30px;
      }
    }
    .cont {
      margin-bottom: 15px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 30px;
      }
    }
    .herobtn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 340px;
      background-color: $body-color;
      padding: 20px 0;
      border-radius: 50px;
      text-decoration: none;
      position: relative;
      transition: $transition-base;
      border: solid 2px $body-color;
      @include media-breakpoint-up(sm) {
        width: 400px;
        padding: 22px 0;
      }
      p {
        color: $white;
        margin: 0;
        @include media-breakpoint-up(sm) {
          font-size: 20px;
        }
      }
      i {
        color: $white;
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        @include media-breakpoint-up(sm) {
          right: 30px;
        }
      }
      &:hover {
        background-color: $white;
        p {
          color: $body-color;
        }
        i {
          color: $body-color;
        }
      }
    }
  }
}
