#priceArea {
  position: relative;
  .priceArea-square {
    position: absolute;
    padding-left: 6vw;
    top: -20px;
    left: 0;
    @include media-breakpoint-up(sm) {
      padding-left: 0;
      padding-right: 10vw;
      top: 40px;
      left: auto;
      right: 0;
    }
    img {
      width: 70%;
      @include media-breakpoint-up(sm) {
        width: 100%;
      }
    }
  }
  .brandContainer {
    .midashi1 {
    }
    .priceSingle {
      padding: 30px;
      text-align: center;
      font-weight: bold;
      height: 100%;
      .name {
        display: inline-block;
        padding: 2px 15px;
        border-radius: 50px;
        margin-bottom: 15px;
      }
      .sub {
        margin-bottom: 20px;
      }
      .price {
        margin-bottom: 15px;
        font-size: 40px;
        font-family: "Impact";
        span {
          font-size: 40px;
        }
      }
      .list {
        list-style: none;
        text-align: left;
        margin: 0;
        padding: 0;
        li {
          margin-bottom: 15px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 10px;
          i {
            color: $primary02;
            padding-top: 5px;
          }
          p {
            margin: 0;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
      dl {
        text-align: left;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin: 0;
        dt {
          width: 70%;
          margin-bottom: 20px;
        }
        dd {
          width: 30%;
          text-align: end;
          font-family: "Impact";
          margin-bottom: 20px;
        }
        &:last-child {
          dt,
          dd {
            margin: 0;
          }
        }
      }
    }
  }
}
