.midashi1 {
  text-align: center;
  color: $body-color;
  font-weight: bold;
  margin-bottom: 30px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 50px;
  }
  .en {
    font-size: 10px;
    margin-bottom: 2px;
    line-height: 1em;
    letter-spacing: -0.05em;
    @include media-breakpoint-up(sm) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  .ja {
    font-size: 25px;
    margin: 0;
    line-height: 1.2em;
    letter-spacing: -0.01em;
    @include media-breakpoint-up(sm) {
      font-size: 32px;
      letter-spacing: 0em;
    }
  }
  &.left {
    text-align: start;
  }
  &.white {
    color: $white;
  }
}
