@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
  font-feature-settings: "pwid";
  letter-spacing: 0.05em;
}
.componentTitle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  opacity: 0.25;
  z-index: $zindex-fixed;
  padding: 15px;
  font-size: 10px;
  transition: $transition-base;
  &:hover {
    opacity: 1;
  }
}
img {
  max-width: 100%;
}
p {
  letter-spacing: 0.05%;
  line-height: 1.8em;
}
@mixin fontSize($font_size: 10) {
  font-size: calc($font_size / 390 * 100vw);
}
@mixin fontSizeSM($font_size: 10) {
  font-size: calc($font_size / 820 * 100vw);
}
@mixin fontSizeLG($font_size: 10) {
  font-size: calc($font_size / 1366 * 100vw);
}
@import "components/header";
@import "components/toggleMenu";
@import "components/layout";
@import "components/summary";
@import "components/outline";
@import "components/digest";
@import "components/digestCover";
@import "components/article";
@import "components/sectionHeader";
@import "components/secondHeader";
@import "components/coverBgImg";
@import "components/sideMenu";
@import "components/tagCloud";
@import "components/narrowThumbnailLink";
@import "components/imgList";
@import "components/carousels";
@import "components/formTable";
@import "components/brandTable";
@import "components/flow";
// @import "components/faq";
@import "components/newsList";
@import "components/newsBar";
@import "components/newsArchive";
@import "components/catchCopyBar";
@import "components/catchCopyBorder";
@import "components/anchorLinks";
@import "components/outlineCard";
@import "components/midashi1";
@import "components/midashi2";
@import "components/midashi3";
@import "components/aboutArea";
@import "components/problemArea";
@import "components/answerArea";
@import "components/featureArea";
@import "components/faqArea";
@import "components/contactArea";
@import "components/heroArea";
@import "components/priceArea";
@import "components/flowArea";
@import "components/animation";
@import "components/footer";
@import "components/vif";
